import React from 'react';

import Seo from '../components/SEO/SEO';

import Guaranty from '../components/Guaranty/Guaranty';
import Subheader from '../components/Subheader/Subheader';
import { SEO_ITEMS } from '../constants/SEOItems';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Гарантія та обмін',
    link: '/guaranty',
  },
];

const GuarantyPage: React.FC = (): JSX.Element => {
  return (
    <>
      <Seo
        breadcrumbs={crumbs}
        description={SEO_ITEMS.guarantyPage.description}
        lang="ua"
        path="/guaranty"
        title={SEO_ITEMS.guarantyPage.title}
      />
      <Subheader crumbs={crumbs} withContainer />
      <Guaranty />
    </>
  );
};

export default GuarantyPage;
