import ExchangeIcon from '../assets/icons/exchange.svg';
import GuarantyIcon from '../assets/icons/guaranty.svg';

export default [
  {
    id: 1,
    Icon: GuarantyIcon,
    title: 'Гарантія',
    description: `Товар, що реалізується і підлягає обов’язковій сертифікаціїї, проходить виробником сертифікацію 
    згідно чинного законодавства України. Товар має строк придатності/ гарантійний строк, 
    встановлений виробником товару. Покупець зобов’язаний дотримуватись умов зберігання Товару 
    або експлуатації/використання товару, встановлених виробником, інакше Продавець звільняється від відповідальності за якість товару.`,
  },
  {
    id: 2,
    Icon: ExchangeIcon,
    title: 'Заміна товару',
    description: `Неякісний товар підлягає обміну на якісний. 
    У випадку виявлення дефекту товару, необхідно звернутись за 
    телефоном <a href='tel:+380637300303' className="binct-phone-number-3">+38 (063) 730-03-03</a>
    <br />
    <br />
    Для попередження неприємних інцидентів і непорозумінь 
    слід зберігати всі документи, що підтверджують покупку товару. 
    Перед експлуатацією товару рекомендується вивчити умови зберігання/використання товару.`,
  },
];
