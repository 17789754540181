import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../Container/Container';

import guarantyData from '../../constants/guarantyData';
import socials from '../../constants/socials';

import './Guaranty.scss';

const CONTACTS_QUERY = graphql`
  query {
    contacts: allCockpitContacts(filter: { lang: { eq: "ua" } }) {
      nodes {
        icon {
          value
        }
        value {
          value
        }
      }
    }
  }
`;

const Guaranty: React.FC = () => {
  const {
    contacts: { nodes: contactsNodes },
  } = useStaticQuery(CONTACTS_QUERY);
  return (
    <div className="guaranty">
      <Container>
        <h2 className="guaranty__title">Гарантія та обмін</h2>
        <div className="guaranty__items">
          {guarantyData.map(({ id, Icon, title, description }) => (
            <div className="guaranty__items-item" key={id + title}>
              <Icon />
              <h2>{title}</h2>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          ))}
        </div>
        <div className="guaranty__consult">
          <h2>У Вас залишилися питання? Зв’яжіться з нами для подальшого вирішення питання і проведення необхідних дій</h2>
          <div className="guaranty__consult-items">
            {contactsNodes.slice(0, 2).map(({ icon, value }) => (
              <div className="guaranty__consult-item" key={value.value}>
                <div dangerouslySetInnerHTML={{ __html: icon.value }} />
                <a
                  className={!value.value.includes('@') && `binct-phone-number-3`}
                  href={value.value.includes('@') ? `mailto:${value.value}` : `tel:${value.value}`}
                >
                  {value.value}
                </a>
              </div>
            ))}
            <div className="guaranty__consult-socials">
              {socials.map(({ Icon, href }) => (
                <a href={href} target="_blank" rel="noreferrer" className="guaranty__consult-socials-item">
                  <Icon />
                </a>
              ))}
            </div>
          </div>
        </div>
      </Container>
      <div className="blue-circle" />
      <div className="yellow-circle" />
    </div>
  );
};

export default Guaranty;
